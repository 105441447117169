var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          { key: 'close_payments', _classes: 'text-center' },
          Object.assign({}, _vm.helpers.validIcons, {key: 'last_day'}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'generate_interests'}),
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/property_close_payments',
          route: '/admin/parameters/property_close_payments',
          name: 'property_close_payment',
          params: { _lists: 'months' },
          duplicate: true,
          latest: 'month_id'
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }