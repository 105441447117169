<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            { key: 'close_payments', _classes: 'text-center' },
            { ...helpers.validIcons, key: 'last_day' },
            { ...helpers.validIcons, key: 'generate_interests' },
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/property_close_payments',
            route: '/admin/parameters/property_close_payments',
            name: 'property_close_payment',
            params: { _lists: 'months' },
            duplicate: true,
            latest: 'month_id'
          }"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyClosePayments',
  mixins: [crudMixin]
}
</script>
